import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

import { IframeContainer } from "../../components/site";
import { CustomerStory } from "../../components/page-template/customer-story"

const Customer = (props) => {
    const website_url = process.env.GATSBY_SITE_URL;

    const [data, setData] = useState({ hits: [] });
    const contentful = require("contentful");
    const client = contentful.createClient({
        space: process.env.GATSBY_CONTENTFUL_SPACEID,
        accessToken: process.env.GATSBY_CONTENTFUL_PREVIEWACCESSTOKEN,
        host: "preview.contentful.com",
    });

    useEffect(() => {
        function fetchData() {
            client
                .getEntry(props.location.search.replace("?", ""))
                .then((entry) => {
                    setData(entry.fields);
                })
                .catch(console.error);
        }
        fetchData();
    }, []);

    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                const alt = node.data.target.fields.image.fields.title;
                const url = node.data.target.fields.image.fields.file.url;
                if (
                    node.data.target.fields.link.startsWith(website_url) ||
                    node.data.target.fields.link.startsWith("/") ||
                    node.data.target.fields.link.startsWith("#")
                ) {
                    return (
                        <Link to={node.data.target.fields.link}>
                            <img className="-rounded -addShadow" alt={alt} src={url} />
                        </Link>
                    );
                } else {
                    return (
                        <a
                            href={node.data.target.fields.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className="-rounded -addShadow" alt={alt} src={url} />
                        </a>
                    );
                }
            },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const alt = node.data.target.fields.title;
                const url = node.data.target.fields.file.url;
                return <img alt={alt} src={url} />;
            },
            [INLINES.HYPERLINK]: (node) => {
                if (node.data.uri.startsWith("#ID=")) {
                    return (
                        <span
                            className="post-anchor"
                            css={{ opacity: "0" }}
                            id={node.data.uri.replace("#ID=", "")}
                        >
                            &nbsp;
                        </span>
                    );
                } else if (node.data.uri.includes("player.vimeo.com/video")) {
                    return (
                        <IframeContainer title="PayHero Vimeo Video" src={node.data.uri} />
                    );
                } else if (node.data.uri.includes("youtube.com/embed")) {
                    return (
                        <IframeContainer
                            title="PayHero YouTube Video"
                            src={node.data.uri}
                        />
                    );
                } else if (
                    node.data.uri.startsWith(website_url) ||
                    node.data.uri.startsWith("/") ||
                    node.data.uri.startsWith("#")
                ) {
                    return (
                        <Link to={node.data.uri}>
                            {node.content.map((edge, i) => {
                                return <span key={i}>{edge.value}</span>;
                            })}
                        </Link>
                    );
                } else {
                    return (
                        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
                            {node.content.map((edge, i) => {
                                return <span key={i}>{edge.value}</span>;
                            })}
                        </a>
                    );
                }
            },
        },
    };

    return (
        <CustomerStory customer={data}
            contentOptions={options}
            location={props.location} />
    );
};

export default Customer;
